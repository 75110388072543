export const environment = {
  production: false,
  backend:{
    baseUrl_setInformativi: "/informative-sets-api/",
    baseUrl_valorizzazioni: "/api/"
  },
  baseUrl: {
    valorizzazioni: "https://cardif-dev.elvoon.it/",
    setinformativi: "https://cardif-dev.elvoon.it/"
  }
};
